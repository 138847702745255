import React, {
  useMemo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  isShellArticle,
  shouldRenderEcommerceRecommendations,
  shouldShowAd,
  getTableOfContentsLabel,
  getTableOfContentsEntries,
  shouldShowTableOfContents,
} from 'lib/article';
import Breakpoints from 'lib/Breakpoints';
import { ArticleContext, VerticalContext, useFeatureFlagContext } from 'lib/ContextTypes';
import {
  articleSection as ArticleSectionPropType,
} from 'lib/CustomPropTypes';
import { verticalSlugMap } from 'lib/vertical';

import Ad from 'components/Ad';
import Recommended from 'components/Recommended';
import { TableOfContents } from 'components/TableOfContents';
import { BodyTaboola } from './BodyTaboola';

const {
  news: NEWS,
  today: TODAY,
  select: SELECT,
} = verticalSlugMap;

/**
 * BodyRightRail component.
 *
 * This component is responsible for rendering the right rail section of the article body.
 * It receives various props to customize its content and behavior.
 *
 * @param {object} props - The properties object.
 * @param {object} props.block - The block object containing CSS class names.
 * @param {object} props.section - The section object containing details about the current section.
 * @param {number} props.sectionIndex - The index of the current section.
 * @param {boolean} props.articleContainsLargeProduct - Flag indicating if the article contains a large product.
 * @param {Function} props.shouldRenderRightRailTaboola - Function to determine if the Taboola widget should be rendered.
 * @param {Function} props.getRightRailAdConfig - Function to get the ad configuration for the right rail.
 * @param {string} props.currentPath - The current URL path.
 * @param {Function} props.boxflexAdRenderCheck - Function to check if the Boxflex ad should be rendered.
 * @param {boolean} props.boxflexRendered - Flag indicating if the Boxflex ad has been rendered.
 * @param {boolean} props.isScrollingUp - Flag indicating if the user is scrolling up.
 * @param {boolean} props.showTaboola - Flag indicating if the Taboola widget should be shown.
 * @returns {React.Element|null} The rendered right rail section or null if conditions are not met.
 */
export const BodyRightRail = ({
  block,
  section,
  sectionIndex,
  articleContainsLargeProduct,
  shouldRenderRightRailTaboola,
  getRightRailAdConfig,
  currentPath,
  boxflexAdRenderCheck,
  boxflexRendered,
  isScrollingUp,
  showTaboola,
}) => {
  const article = useContext(ArticleContext);
  const vertical = useContext(VerticalContext);
  const [isMobileOrTabletBreakpoint, setIsMobileOrTabletBreakpoint] = useState(false);
  const [shouldRenderRecircSelect, setShouldRenderRecircSelect] = useState(false);

  useEffect(() => {
    /**
     *
     */
    const monitorBreakpoint = () => {
      setIsMobileOrTabletBreakpoint(Breakpoints.isSorM());
    };

    monitorBreakpoint();

    window.addEventListener('resize', monitorBreakpoint);

    return () => {
      window.removeEventListener('resize', monitorBreakpoint);
    };
  }, []);

  useEffect(() => {
    const { ad = {} } = section;

    const sectionElement = document.querySelectorAll('.article-body__section')[sectionIndex + 1];
    const isContainerTallEnough = sectionElement && sectionElement.querySelector('.article-body').offsetHeight >= 600;

    setShouldRenderRecircSelect(vertical === SELECT && ad.display && isContainerTallEnough);
  }, [vertical, section, sectionIndex]);

  const { 'show-table-of-contents': tableOfContentsFeatureFlag } = useFeatureFlagContext();
  const tableOfContentsEnabled = shouldShowTableOfContents(
    tableOfContentsFeatureFlag,
    vertical,
    article?.taxonomy?.allTerms,
  );

  const { tableOfContentsLabel, tableOfContentsLinks } = useMemo(() => {
    if (!tableOfContentsEnabled) {
      return {
        tableOfContentsLabel: '',
        tableOfContentsLinks: [],
      };
    }

    return {
      tableOfContentsLabel: getTableOfContentsLabel(article?.taxonomy?.allTerms || []),
      tableOfContentsLinks: getTableOfContentsEntries(article?.body, vertical),
    };
  }, [article?.articleBody, vertical, article?.taxonomy?.allTerms, tableOfContentsEnabled]);

  if (!article || !vertical) {
    return null;
  }

  const { ad = {}, isFullWidth, hideRightRail } = section;
  const {
    ecommerceEnabled,
    nativeAd,
    url,
  } = article;

  if (isFullWidth || hideRightRail) {
    return null;
  }

  const isFirstSection = sectionIndex === 0;
  const isThirdSection = sectionIndex === 2;

  const isSectionForTodayRecommendationWidget = articleContainsLargeProduct
    ? isThirdSection
    : isFirstSection;

  const hasShortTableOfContents = tableOfContentsLinks.length < 5;

  const hasLongTableOfContents = tableOfContentsLinks.length >= 5;

  let isSectionForTableOfContents = null;
  if (articleContainsLargeProduct) {
    isSectionForTableOfContents = hasShortTableOfContents
      || (hasLongTableOfContents && isThirdSection);
  } else {
    isSectionForTableOfContents = hasShortTableOfContents || hasLongTableOfContents;
  }

  const shouldRenderTableOfContents = tableOfContentsEnabled
    && !isMobileOrTabletBreakpoint
    && isSectionForTableOfContents;

  const renderECommerceWidget = shouldRenderEcommerceRecommendations(article, vertical);

  const displayTodayEcommerceWidget = isSectionForTodayRecommendationWidget
      && renderECommerceWidget === TODAY;

  const adConfig = typeof getRightRailAdConfig === 'function'
    ? getRightRailAdConfig({
      section,
      nativeAd,
      sectionIndex,
      isFirstSection,
      isThirdSection,
    })
    : ad;
  const shouldRenderAd = adConfig.display && shouldShowAd(article) && !ecommerceEnabled;

  const shouldRenderTaboola = typeof shouldRenderRightRailTaboola === 'function'
    ? shouldRenderRightRailTaboola({
      section,
      nativeAd,
      sectionIndex,
      isFirstSection,
      isThirdSection,
    })
    : !isShellArticle(article) && section.taboola && !Breakpoints.isS();

  const isAnyAdditionalLabelOnTheShow = (
    article?.taxonomy?.additionalTerms ?? []
  ).find((label) => label?.name?.toLowerCase() === 'on the show');
  const isPrimaryLabelOnTheShow = (article?.taxonomy?.primaryLabel?.name ?? '').toLowerCase() === 'on the show';
  const hasOnTheShowLabel = isAnyAdditionalLabelOnTheShow || isPrimaryLabelOnTheShow;
  const isPrimarySectionShop = (article?.taxonomy?.primarySection?.name ?? '').toLowerCase() === 'shop';
  const shouldRenderShopTheShowRecirc = hasOnTheShowLabel && isPrimarySectionShop;

  if (shouldRenderTaboola
    || section.newsletter
    || shouldRenderAd
    || displayTodayEcommerceWidget
    || shouldRenderRecircSelect
    || shouldRenderTableOfContents
  ) {
    return (
      <div
        data-test="right-rail-container"
        data-testid="right-rail-container"
        className={classNames(
          `${block}__right-rail`,
          'layout-grid-item',
          'grid-col-4_5-l',
          'grid-col-3_5-xl',
          'grid-col-push-half-xl',
          {
            [`${block}__right-rail-ecom di-l dn`]: displayTodayEcommerceWidget,
            HFSBumped: isScrollingUp,
          },
        )}
      >
        {shouldRenderTaboola && (
          <BodyTaboola
            isRail
            article={article}
            showTaboola={showTaboola}
            data-test="right-rail-taboola"
          />
        )}
        <div className={classNames({ [`${block}__right-rail--sticky`]: !boxflexRendered })}>
          {shouldRenderTableOfContents && (
            <TableOfContents
              isCollapsible={false}
              title={tableOfContentsLabel}
              links={tableOfContentsLinks}
            />
          )}
          {displayTodayEcommerceWidget && shouldRenderShopTheShowRecirc && (
            <Recommended
              isShopTheShow
              isTrending
              url={url.primary}
              additionalClasses={classNames(
                `${block}__right-rail--recommended`,
                {
                  [`${block}__non-sticky`]: boxflexRendered,
                  pb1: boxflexRendered,
                },
              )}
              isRightRail
              data-test="right-rail-today-ecom"
              pageRegion="article-sidebar"
            />
          )}
          {shouldRenderRecircSelect && (
            <Recommended
              isRightRail
              currentPath={currentPath}
              isSelect
              url={url.primary}
              additionalClasses={classNames(
                `${block}__right-rail--recommended`,
                {
                  [`${block}__non-sticky`]: boxflexRendered,
                  pb1: boxflexRendered,
                },
              )}
              data-test="right-rail-select"
              pageRegion="article-sidebar"
            />
          )}
          {renderECommerceWidget === NEWS && (
            <Recommended
              isNBCNewsCommerce
              url={url.primary}
              additionalClasses={classNames(
                `${block}__right-rail--recommended`,
                {
                  [`${block}__non-sticky`]: boxflexRendered,
                  pb1: boxflexRendered,
                },
              )}
              isRightRail
              data-test="right-rail-news-ecom"
              pageRegion="article-sidebar"
            />
          )}
          {displayTodayEcommerceWidget && !shouldRenderShopTheShowRecirc && (
            <Recommended
              isTrending
              url={url.primary}
              additionalClasses={classNames(
                `${block}__right-rail--recommended`,
                {
                  [`${block}__non-sticky`]: boxflexRendered,
                  pb1: boxflexRendered,
                },
              )}
              isRightRail
              data-test="right-rail-today-ecom"
              pageRegion="article-sidebar"
            />
          )}
        </div>
        {shouldRenderAd && (
          <Ad
            slot={(isFirstSection && vertical === NEWS) ? 'boxrail' : adConfig.slot}
            enumerator={adConfig.index}
            adClass={`${block}__right-rail--ad`}
            offset={10}
            handler={boxflexAdRenderCheck}
            data-test="right-rail-ad"
          />
        )}
      </div>
    );
  }

  return null;
};

BodyRightRail.propTypes = {
  block: PropTypes.string,
  section: ArticleSectionPropType.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  articleContainsLargeProduct: PropTypes.bool,
  shouldRenderRightRailTaboola: PropTypes.func,
  getRightRailAdConfig: PropTypes.func,
  currentPath: PropTypes.string,
  boxflexAdRenderCheck: PropTypes.func,
  boxflexRendered: PropTypes.bool,
  isScrollingUp: PropTypes.bool,
  showTaboola: PropTypes.bool,
};

BodyRightRail.defaultProps = {
  block: '',
  articleContainsLargeProduct: false,
  shouldRenderRightRailTaboola: null,
  getRightRailAdConfig: null,
  currentPath: '',
  boxflexAdRenderCheck: null,
  boxflexRendered: false,
  isScrollingUp: false,
  showTaboola: false,
};
