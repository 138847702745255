import { ARTICLE, AUTHENTICATED } from 'lib/myNewsConstants';
import { useEffect } from 'react';

/**
 * Custom hook to save the article history when authenticated.
 *
 * @param {object} params - The parameters for the hook.
 * @param {string} params.authenticationState - The current authentication state.
 * @param {string} params.savedContentId - The ID of the saved content.
 * @param {Article} params.article - The article object.
 * @param {string} params.mparticleId - The mParticle ID.
 * @param {Function} params.saveHistory - The function to save the history.
 * @param {string} params.articleId - The ID of the article.
 */
export function useSaveArticleHistory({
  authenticationState,
  savedContentId,
  article,
  mparticleId,
  saveHistory,
  articleId,
}) {
  useEffect(() => {
    if (authenticationState === AUTHENTICATED && (savedContentId !== article?.id) && mparticleId) {
      saveHistory({ contentId: articleId, contentType: ARTICLE });
    }
  }, [authenticationState, article?.id, mparticleId, saveHistory, savedContentId]);
}
