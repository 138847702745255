import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  person as PersonPropType,
  author as AuthorPropType,
} from 'lib/CustomPropTypes';

import { BylineList } from 'components/Byline';
import { getDataActivityMapID } from 'lib/articleUtils';
import { verticalSlugMap } from 'lib/vertical';
import './styles.themed.scss';

// Render function to generate the Authors portion of the byline.

export const ArticleInlineByline = ({ authors, vertical }) => {
  if (!authors || !authors.length) {
    return null;
  }

  const { t } = useTranslation();

  const { msnbc } = verticalSlugMap;
  const MSNBCArticle = vertical === msnbc;

  const dataActivityMapID = getDataActivityMapID({
    pageRegion: 'article-top',
    componentName: 'inline-byline',
  });

  return (
    <div
      className="article-inline-byline"
      data-test="article-byline-inline"
      data-testid="article-byline-inline"
      data-activity-map={dataActivityMapID}
    >
      {`${t('By')} `}
      <BylineList authors={authors} showJobTitle={MSNBCArticle} />
    </div>
  );
};

ArticleInlineByline.propTypes = {
  authors: PropTypes.arrayOf(
    AuthorPropType || PersonPropType,
  ),
  vertical: PropTypes.string.isRequired,
};

ArticleInlineByline.defaultProps = {
  authors: null,
};
