import { ArticleInlineByline } from 'components/Article/ArticleInlineByline';
import { SkipToTableOfContentsOverlay } from 'components/Article/SkipToTableOfContentsOverlay';
import { BookmarkButton } from 'components/BookmarkButton';
import ContentTimestamp from 'components/ContentTimestamp';
import { LinkIfHref } from 'components/Link';
import { SocialShareMenu } from 'components/SocialShareMenu';
import { isShellArticle } from 'lib/article';
import { SHOW_USER_ACTIONS } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { ARTICLE } from 'lib/myNewsConstants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

/**
 * StandardByline component renders the byline section of an article.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.isLiveBlog - Indicates if the article is a live blog.
 * @param {boolean} props.showUserActions - Indicates if user actions should be shown.
 * @param {boolean} props.showBylineTimestamp - Indicates if the byline timestamp should be shown.
 * @param {boolean} props.showInlineByline - Indicates if the inline byline should be shown.
 * @param {VerticalType} props.vertical - The vertical of the article.
 * @param {string} props.bylineTimestampDatePublished - The date the byline was published.
 * @param {Article} props.article - The article object.
 * @param {string} props.contentId - The content ID of the article.
 * @param {string} props.block - The block class name.
 * @param {boolean} props.tableOfContentsEnabled - Indicates if the table of contents is enabled.
 * @param {boolean} props.showCreatedDate - Indicates if the created date should be shown.
 * @returns {React.ReactElement|null} The rendered component or null if the article is a shell article.
 */
export function StandardByline({
  isLiveBlog = false,
  showUserActions = false,
  showBylineTimestamp = false,
  showInlineByline = false,
  vertical = '',
  bylineTimestampDatePublished = '',
  article,
  contentId,
  block,
  tableOfContentsEnabled = false,
  showCreatedDate = false,
}) {
  const {
    ecommerceEnabled,
    headline,
    source,
    taxonomy,
    url,
    date: { createdAt: dateCreated, publishedAt: articleDatePublished },
    breakingNews,
    authors: articleAuthors = [],
  } = article;

  if (isShellArticle(article)) {
    return null;
  }

  const authors = articleAuthors.filter(({ authorType }) => authorType === 'author');

  const { organization: { name: orgName, externalUrl: orgUrl } = {} } = source || {};

  const isLiveBreakingNews = breakingNews || isLiveBlog;
  const showSubline = showUserActions || showBylineTimestamp || showInlineByline;
  const userActions = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
  const { showPinterest = false, showBookmark = false } = userActions || {};
  const datePublished = bylineTimestampDatePublished || articleDatePublished;

  return (
    <>
      {showSubline && (
        <section className={classNames({
          mb7: !ecommerceEnabled && !isLiveBreakingNews,
          mb6: !ecommerceEnabled && isLiveBreakingNews,
        })}
        >
          {showUserActions && !showPinterest ? (
            <div className="article-social-share-top" data-testid="article-body-social-share-menu">
              <SocialShareMenu
                url={url.primary}
                headline={headline.tease}
                contentId={contentId}
                pageRegion="article-top"
                trackingEventName="article_social_share"
              />
            </div>
          ) : null}

          {showUserActions && showBookmark ? (
            <div className="article-bookmark-menu" data-testid="article-body-bookmark-menu">
              <BookmarkButton contentId={contentId} contentType={ARTICLE} pageRegion="article-top" />
            </div>
          ) : null}

          {showBylineTimestamp && (
            <div className={`${block}__date-source`} data-testid="article-body-timestamp">
              <ContentTimestamp
                dateCreated={dateCreated}
                datePublished={datePublished}
                showCreatedDate={showCreatedDate}
                delimiter=", "
                meta
                hideMicroData
              />
              {source && orgName && (
                <span data-testid="article-body-source-date">
                  {(dateCreated || datePublished) ? ' / ' : ''}
                  <Trans>
                    Source
                  </Trans>
                  {': '}
                  <LinkIfHref className="article-source__link" href={orgUrl}>
                    {orgName}
                  </LinkIfHref>
                </span>
              )}
            </div>
          )}
          {showInlineByline && (
            <div className="relative">
              <ArticleInlineByline
                authors={authors}
                vertical={vertical}
                taxonomy={taxonomy}
              />
              {tableOfContentsEnabled && <SkipToTableOfContentsOverlay className="byline" />}
            </div>
          )}
        </section>
      )}
    </>
  );
}

StandardByline.propTypes = {
  isLiveBlog: PropTypes.bool,
  showUserActions: PropTypes.bool,
  showBylineTimestamp: PropTypes.bool,
  showInlineByline: PropTypes.bool,
  vertical: PropTypes.string,
  bylineTimestampDatePublished: PropTypes.string,
  article: PropTypes.shape({
    id: PropTypes.string,
    breakingNews: PropTypes.bool,
    breakingTagDisabled: PropTypes.bool,
    isLiveBlog: PropTypes.bool,
    ecommerceEnabled: PropTypes.bool,
    headline: PropTypes.string,
    source: PropTypes.shape({
      organization: PropTypes.shape({
        name: PropTypes.string,
        externalUrl: PropTypes.string,
      }),
    }),
    taxonomy: PropTypes.shape({
      primarySection: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
    date: PropTypes.shape({
      createdAt: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
    body: PropTypes.arrayOf(PropTypes.object),
    authors: PropTypes.arrayOf(PropTypes.shape({
      authorType: PropTypes.string,
      name: PropTypes.string,
    })),
    adsEnabled: PropTypes.bool,
    nativeAd: PropTypes.bool,
  }).isRequired,
  contentId: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  tableOfContentsEnabled: PropTypes.bool,
  showCreatedDate: PropTypes.bool,
};
