import React from 'react';
import PropTypes from 'prop-types';
import { SkipLinkOverlay } from 'components/SkipLinkOverlay';

/**
 * Overlays a button that focuses the table of contents when clicked
 * @param {object} props - The component props.
 * @param {string} [props.className] - Additional class name for styling.
 * @returns {React.JSX}
 */
export function SkipToTableOfContentsOverlay({ className }) {
  /**
   * @returns {void}
   */
  const focusTableOfContents = () => {
    const tableOfContents = document.querySelector('#table-of-contents');
    const firstFocusableChild = tableOfContents?.querySelector('a:not([disabled]), button:not([disabled])');

    firstFocusableChild?.focus();
  };

  return (
    <SkipLinkOverlay
      buttonText="Skip to table of contents"
      onClick={focusTableOfContents}
      showIcon
      className={className}
    />
  );
}

SkipToTableOfContentsOverlay.propTypes = {
  className: PropTypes.string,
};

SkipToTableOfContentsOverlay.defaultProps = {
  className: '',
};
